/* Customize the label (the container) */
@import 'variables';
@import 'mixins';
@import '~bootstrap/scss/bootstrap';
@import 'NovelVeltins.css';
@import 'Atreyu';

body {
    font-family: 'Novel Veltins';
    font-weight: 600;
}

:focus {
    outline: none;
}

.btn {
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
#textColorSocialMedia {
    font-weight: 800;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

.center-img {
    margin: 0 auto;
    margin-top: 20pt;
    margin-bottom: 20pt;
}

.text-hide {
    @include text-hide();
}

.brand {
    font-family: 'Atreyu';
    font-weight: normal;
    letter-spacing: 0.01em;
    text-transform: none;
}

.impressum-container h2 {
    color: $primary;
}

.buy-puelleken {
    color: $primary;
    line-height: 140% !important;
}

.note {
    @media only screen and (min-width: 768px) {
        position: absolute;
        top: -171px;
        left: 0px;
        width: 250px;
    }

    @media only screen and (min-width: 1000px) {
        width: 300px;
    }

    @media only screen and (min-width: 1901px) {
        width: 400px;
    }
}

.note-wrapper {
    object-fit: cover;

    &.container {
        padding-left: 0;
        padding-right: 0;
    }

    .logo {
        height: 130px;
    }
}

.helle-puelleken {
    h2.brand {
        font-size: 75px;
        @media only screen and (min-width: 768px) {
            font-size: 110px;
        }
        color: $primary;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
        transform: translateY(15%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fluid-container {
    overflow-x: hidden;
}
.img-fluid {
    align-self: center;
}

.header-container {
    @media only screen and (min-width: 768px) {
        margin-bottom: 100px;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
        max-width: 1300px;
        max-height: 100vh;
        max-height: calc(100vh - 120px);
        margin: 0 auto;
        object-fit: contain;
    }
}

#map_container {
    margin: 20px;
    border: 5pt solid $primary;
    padding: 0;
}

// body.modal-open #header {
//     position: absolute !important;
//     left: 0;
//     right: 0;
//     top: 0;
//     z-index: 1052;
// }

.copyright {
    text-align: right;
    font-size: 12px;
}

// body.modal-open .header-wrapper {
//     height: 120px;
// }

// body.modal-open .banner {
//     z-index: 1094;
// }

// body.modal-open .fluid-container {
//     position: relative;
// }

.image-container {
    display: flex;
    position: relative;
    margin-bottom: 100px;
}

.buy-puelleken {
    font-size: 25px;
}

.buy-puelleken-container {
    margin-top: 90px;
    margin-bottom: 90px;
}

.impressum-container strong {
    font-size: 19px;
}

.impressum-container a {
    color: $primary !important;
}

.image-container .puelleken {
    height: 370px;
    position: relative;
    left: 100px;
    width: auto;
    margin-bottom: 50px;
    object-fit: contain;
}

.textColor {
    color: $primary;
}

.btn {
    width: 200px;
    border-radius: 0;
    text-align: center;
    margin: 0 auto;
}

.pulleken-einzel-flasche.center-img {
    margin-top: 139px;
    margin-left: 150px;
    margin-bottom: 0;
}

#handlerSearch {
    margin: 30px 0;
    transform: rotate(-20deg);
}

#quote {
    color: $primary;
    font-size: xx-large;
    text-align: center;
    margin-top: 20pt;
    margin-bottom: 50pt;
    transform: rotate(-20deg);
}

.button_optional {
    background-color: $primary;
    font-size: 29px;
    text-transform: uppercase;
    border: none;
    padding: 20px;
    width: 53%;
}

.button_age {
    background-color: $primary;
    width: 80%;
}

.overlay {
    /*position: fixed;*/
    z-index: 3;

    /*margin:20pt;*/
}

.ig-images > div {
    margin-top: 15px;
}

.ig-images > div > div {
    padding-top: 100%;
    width: 100%;
    position: relative;
}

.ig-images img {
    position: absolute;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
}

.ig-images {
    margin-right: -30px;
}

// .modal {
//     z-index: 1100;
// }

// .modal-dialog {
//     top: 50%;
//     transform: translateY(-50%) !important;
// }

// .modal-dialog.modal-bg .modal-content {
//     background: url('../images/background.jpg') repeat-y;
//     background-size: 100%;
// }

.cookie_text {
    margin: 20pt;
}

#title {
    color: $primary;
    text-align: center;
    font-size: 90px;
}

#nutritons {
    h2 {
        font-size: 23px;
        .brand {
            font-size: 2em;
        }

        @media only screen and (min-width: 768px) {
            font-size: 31px;
        }

        @media only screen and (min-width: 1901px) {
            font-size: 50px;
        }

        font-weight: 600;
    }

    h3 {
        font-weight: bold;
        font-size: 26px;
        background-image: linear-gradient(to right, $primary 33%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 9px 4px;
        background-repeat: repeat-x;
        padding-bottom: 15px;
    }

    table td {
        border-color: $primary;
    }
}

#titleTraditional {
    display: inline-block;

    font-size: 40pt;
}

#titleNormal {
    color: $primary;
    text-align: left;

    margin-bottom: 25px;
    font-size: 37px;
}

#header {
    background-color: $primary;
    color: #eee;
    margin: 0;

    a:hover {
        color: #eee;
        text-decoration: none;
    }
    .brand {
        font-size: 60px;
        color: #eee;
        vertical-align: middle;
    }
}

.schild {
    height: auto;
    width: 15%;
}

.banner {
    position: absolute;
    right: 70px;
    top: 0;
    height: 250px;
    width: auto;
}

.quote-wrapper {
    display: flex;
    justify-content: center;
    background: url('../images/Papierschnipsel.png') no-repeat;
    background-size: 100% 100%;
    padding: 160px 0;
}

// .header-modal-age-check {
//     justify-content: center;
//     border-bottom: 3px solid $primary;
// }

// .modal-links,
// .modal-links a {
//     color: #fff !important;
//     z-index: 1060;
// }

.footer-images {
    width: auto;
}

.footer-images > div {
    width: 50px;
    margin-right: 10px;
}

.footer-images > div img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.footerElement {
    align-items: center;
    display: flex;
}

.backButton {
    color: $primary;
    font-size: xx-large;
}

.textColor {
    color: $primary;
    font-size: 32px;
}

.titleSocialMedia {
    color: $primary;
    font-size: 50px;
    .brand {
        font-size: 110px;
    }
}

#textColorSocialMedia {
    color: $primary;
    font-size: 44px;
}

.socialRotation {
    transform: rotate(-3deg);
    width: 300px;
}

.imageMargin {
    margin-top: 200pt;
    margin-bottom: 200pt;
}

#boldLine {
    width: 1px;
    background: $primary;
    display: inline-block;
    margin: 0 15px;
}

.our-puelleken-img {
    height: 110px;
}

.our-puelleken-container {
    margin-top: 120px;
    margin-bottom: 120px;
    padding: 1rem 4.5rem;
    display: flex;
    align-items: center;
}

.our-puelleken {
    font-weight: bold;
    text-transform: uppercase;
    color: $primary;
    font-size: 52px;
    .brand {
        font-size: 60px;
    }
}

.social-icons-wrapper img {
    width: 80px;
    height: 80px;
}

.borderLine {
    margin-left: 70px;
    margin-right: 70px;
    border-color: $container-border-color;
    border-width: $container-border-width;
    border-style: solid;
}

.borderLine p.textColor {
    font-size: 50px;
    text-align: left;
    line-height: 1.4;
}

.overlay-box > img {
    margin-bottom: 0;
}

#header {
    padding: 5px 70px;
}

.customHeadLine {
    position: relative;
}

#puelleken-kombie {
    position: absolute;
    bottom: -215px;
    z-index: 99;
    right: -22%;
    top: 75px;
}

#puelleken-kombie > img {
    width: 100%;
    object-fit: contain;
    margin: 0;
    height: auto;
}

#puelleken-kombie.show > img {
    animation: fadein 2s;
}

.traeger-wrapper {
    text-align: center;

    img {
        object-fit: contain;

        &.socialRotation {
            transform: none;
        }
    }
}

.viererTraeger {
    height: auto;

    @media only screen and (min-width: 550px) {
        width: 35%;
        margin-top: 0;
        margin-bottom: -5%;
        margin-left: 30%;
    }
}

.secherTraeger {
    height: auto;

    @media only screen and (min-width: 550px) {
        display: inline-block;
        width: 40%;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.kasten-gebinde {
    display: inline-block;

    @media only screen and (min-width: 550px) {
        width: 55%;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.socialRotation > * {
    line-height: 1;
}

#quoteVeltins {
    color: $primary;
    font-size: larger;
    transform: rotate(-3deg);

    margin-bottom: 20pt;
}

.social-button {
    background: $primary;
    color: white;
    font-size: 30pt;
    border-radius: 10pt;

    display: inline-block;
    margin-bottom: 25pt;
    width: 60px;
    height: 60px;
}

#imgMargin {
    margin-top: 10pt;
    margin-bottom: 10pt;
}

.inlineBlock {
    display: inline-block;
}

.wholePage {
    width: 100%;
    height: 100%;

    background-color: none;
    z-index: 2;
    cursor: pointer;
    pointer-events: none;

    /* for "disabled" effect */
    /*opacity: 0.2;
    background: rgb(14, 1, 1);*/
}

.impressumDistance {
    margin-bottom: 20pt;
    font-size: 40pt;
}

#mainPage {
    cursor: pointer;
}

a:link {
    color: black;
}

/* visited link */
a:visited {
    color: white;
}

/* mouse over link */
a:hover {
    color: black;
}

/* selected link */
a:active {
    color: blue;
}

.footerElement .links {
    width: auto;
}

.footerElement .links a {
    color: #fff;
    text-decoration: none;
}

@media only screen and (max-width: 420px) {
    #title {
        color: $primary;
        text-align: center;
        font-size: 40pt;
    }

    .banner {
        display: inline;
        float: right;
    }

    #header {
        background-color: $primary;
        font-size: large;
        color: #eee;
    }
}

@media only screen and (min-width: 496px) {
    #quote {
        margin-top: 20pt;
        color: $primary;
        text-align: center;
        font-size: 40pt;
    }
}

@media only screen and (min-width: 768px) {
    #quote {
        margin-top: 20pt;
        color: $primary;
        text-align: center;
        font-size: 45pt;
    }

    .impressumDistance {
        margin-bottom: 50pt;
    }
}

@media only screen and (min-width: 1080px) {
    .impressumDistance {
        margin-bottom: 80pt;
    }
}

@import 'mobile.scss';
