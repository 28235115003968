
.shop-logos {
    gap: 30px;

    @media only screen and (min-width: 768px) {
        gap: 60px;
    }
}

.shop-logos-row {
    gap: 30px;
    flex-flow: column;
    justify-items: center;
    align-items: center;

    @media only screen and (min-width: 768px) {
        gap: 60px;
        flex-flow: row;
    }

    a {
        flex: 3;
        display: block;

        &.rewe-link {
            flex: 2
        }
    }
}

.amazon-logo {
    width: 100%;
    max-width: 300px;
}

.hofladen-sauerland-logo {
    width: 100%;
    max-width: 300px;
}

.flaschenpost-logo {
    width: 100%;
    max-width: 450px;
}

.rewe-logo {
    width: 100%;
    max-width: 200px;
}
