@font-face {
    font-family: "Novel Veltins_n4";
    src: url("NovelVeltins/NovelVeltins-Regular.eot#iefix") format("eot");
}

@font-face {
    font-family: "Novel Veltins";
    src: url("NovelVeltins/NovelVeltins-Regular.eot#iefix");
    src: url("NovelVeltins/NovelVeltins-Regular.eot#iefix") format("eot"),
        url("NovelVeltins/NovelVeltins-Regular.woff2") format("woff2"),
        url("NovelVeltins/NovelVeltins-Regular.woff") format("woff"),
        url("NovelVeltins/NovelVeltins-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Novel Veltins_n6";
    src: url("NovelVeltins/NovelVeltins-SemiBd.eot#iefix") format("eot");
}

@font-face {
    font-family: "Novel Veltins";
    src: url("NovelVeltins/NovelVeltins-SemiBd.eot#iefix");
    src: url("NovelVeltins/NovelVeltins-SemiBd.eot#iefix") format("eot"),
        url("NovelVeltins/NovelVeltins-SemiBd.woff2") format("woff2"),
        url("NovelVeltins/NovelVeltins-SemiBd.woff") format("woff"),
        url("NovelVeltins/NovelVeltins-SemiBd.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Novel Veltins_n8";
    src: url("NovelVeltins/NovelVeltins-XBold.eot#iefix") format("eot");
}

@font-face {
    font-family: "Novel Veltins";
    src: url("NovelVeltins/NovelVeltins-XBold.eot#iefix");
    src: url("NovelVeltins/NovelVeltins-XBold.eot#iefix") format("eot"),
        url("NovelVeltins/NovelVeltins-XBold.woff2") format("woff2"),
        url("NovelVeltins/NovelVeltins-XBold.woff") format("woff"),
        url("NovelVeltins/NovelVeltins-XBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}