
@import '../scss/_variables.scss';

.ig-images{
    img{
        opacity: 0;
        transform: scale(1.4);
        transition: all 1s;
        &.loaded{
        opacity: 1;
        transform: scale(1);
        }
    }
}
