
@import '../scss/_variables.scss';

.buy-puelleken-container {
    padding-left: 0;
    padding-right: 0;
}
.storefinder-col {
    padding-left: 0;
    padding-right: 0;
}
.storefinder {
    border: $container-border-width solid $container-border-color;

    .gm-style-iw {
        a {
            color: $primary;
        }
    }
}
.storefinder-search-input {
    width: 100%;
    border: 5px solid $container-border-color;
    padding: .5em .8em;
    font-size: 20px;
    transition: transform 0.3s ease-out;

    &:focus {
        outline: none;
        @media only screen and (min-width: 769px) {
            transform: rotate(3deg);
        }
    }
}
