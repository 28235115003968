@media only screen and (max-width: 500px) {
    .banner {
        height: 85px !important;
    }

    #titleNormal {
        font-size: 33px !important;
    }

    .textColor {
        font-size: 29px !important;
    }
}

@media only screen and (min-width: 1900px) {
    .container {
        max-width: 1460px !important;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
    .our-puelleken-container .textColor br {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
    .pulleken-einzel-flasche.center-img {
        margin-left: 25px !important;
    }

    .our-puelleken-container p br {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1900px) {
    .our-puelleken-container {
        padding: 1rem 4rem;
    }

    .borderLine p.textColor {
        font-size: 31px;
    }

    .banner {
        height: 170px;
    }

    #header img {
        width: 150px;
    }

    .pulleken-einzel-flasche.center-img {
        margin-left: 60px;
        margin-top: 100px;
    }

    .customHeadLine.borderLine::before {
        right: 37%;
    }

    .customHeadLine.borderLine::after {
        left: 97%;
    }

    .note-wrapper .logo {
        height: 112px;
    }

    #puelleken-kombie {
        right: -22%;
    }

    .textColor {
        font-size: 22px;
    }

    #titleNormal {
        font-size: 26px;
    }

    .quote-wrapper img {
        height: 186px;
        object-fit: contain;
    }

    .social-container {
        margin-top: 0 !important;
    }

    .button_optional {
        font-size: 18px;
        width: 60%;
        padding: 15px;
    }

    .our-puelleken {
        font-size: 36px;
    }

    .our-puelleken-img {
        height: 75px;
    }

    .quote-wrapper {
        padding: 90px 0;
    }

    .modal-xl {
        max-width: 800px;
    }

    #nutritons img {
        height: 80px;
    }
}

@media only screen and (max-width: 767px) {
    .modal-dialog {
        top: 0;
        transform: none !important;
    }

    .copyright {
        text-align: center;
    }

    .textColor {
        font-size: 23px !important;
    }

    .pulleken-einzel-flasche.center-img {
        max-height: 380px;
        object-fit: contain;
    }

    .note-wrapper .logo {
        max-height: 130px !important;
        height: auto !important;
    }

    .impressum-container p,
    .impressum-container h2 {
        text-align: center;
    }

    .pulleken-einzel-flasche.center-img {
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 0;
        margin-bottom: -50px;
    }

    #header {
        padding: 0;
        height: 70px;
        padding: 10px 20px;
        a {
            display: block;
            height: 100%;
        }
        img {
            height: 100%;
            width: auto;
        }
        .brand {
            font-size: 40px;
        }
    }

    .borderLine {
        margin: 0;
    }

    .social-icons-wrapper img {
        height: 40px;
        width: 40px;
    }

    .ig-images {
        margin-right: 0;
        margin-top: 30px;
    }

    .our-puelleken-container .d-flex.align-items-center {
        flex-direction: column;
    }

    .our-puelleken-container {
        padding: 1rem 1rem;
    }

    .our-puelleken-container p br {
        display: none;
    }

    .titleSocialMedia img {
        margin: 0 auto;
    }

    #textColorSocialMedia {
        font-size: 32px;
    }

    .our-puelleken {
        font-size: 32px;
    }

    .our-puelleken-img {
        height: auto;
        width: 70%;
    }

    .image-container .puelleken {
        height: auto;
        width: 100%;
        left: 0;
        margin-bottom: 0;
    }

    #puelleken-kombie {
        position: relative;
        bottom: 0;
        left: -46px;
        right: 0;
        top: 0;
    }

    #titleNormal {
        text-align: center;
    }

    .image-container {
        flex-direction: column;
        margin-bottom: 0;
        margin-top: 0;
    }

    #puelleken-kombie > img {
        width: 100%;
        margin: 0;
        height: auto;
    }

    #quoteVeltins {
        margin-bottom: 0px;
    }

    .quote-wrapper img {
        margin-top: 0 !important;
    }

    .flex-border-wrapper {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .quote-wrapper {
        flex-direction: column-reverse;
        background-image: url('../images/c761ff03-aca9-4136-8783-f39a6843df95.png');
        padding: 100px 0;
    }

    #quoteVeltins {
        transform: none;
        text-align: center;
    }

    .socialRotation {
        transform: none;
    }

    .button_optional {
        width: 100% !important;
        font-size: 1rem;
        padding: 10px;
    }

    .buy-puelleken {
        font-size: 22px;
    }

    #handlerSearch {
        transform: none;
        margin-bottom: 10px;
        margin-top: 35px;
    }

    .footer-images {
        width: 100%;
    }

    .footer-images > div {
        width: 33%;
    }

    #nutritons {
        padding: 10px !important;
    }

    #nutritons .row > div {
        padding: 0;
    }

    .footer-images > div img {
        object-fit: contain;
    }

    .footerElement .links {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .quote-wrapper {
        background-size: cover;
        background-position: center center;
    }

    .banner {
        height: 135px;
        right: 35px;
        @media only screen and (max-width: 370px) {
            right: 5px;
        }
    }

    .customHeadLine.borderLine::before {
        right: 0;
    }

    .borderLine p.textColor {
        text-align: center;
        font-size: 30px;
    }
}

.social-container {
    margin-top: -75px;
}

.container {
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 1160px;
}

a {
    color: #fff;
}

body {
    background: url('../images/background.jpg') repeat-y;
    background-size: 100%;
}

.panel {
    margin: auto;
    width: 100%;
    padding: 10px;
    border-width: 0px;
}

.note-wrapper {
    position: relative;
}

#nutritons {
    background: url('../images/background.jpg') repeat-y;
    background-size: 100%;
    padding: 40px;
    margin: 0;
    border-radius: 0;
}

#nutritons .table tr:first-child td {
    border: none;
}

.panel > .panel-heading {
    background-color: #6495ed;
    color: #fff;
    text-align: center;
    font-size: 2.3rem;
    height: 55px;
    width: 100%;
    display: block;
}

.panel.active > .panel-heading {
    background-color: #1e90ff;
}

.panel > .panel-body {
    display: none;
}

.panel.active > .panel-body {
    display: block;
}

.btn-step {
    border-width: 2px;
    border-style: solid;
    line-height: 25px;
    text-transform: uppercase;
    font-weight: bold;
}

.btn-step:hover {
    background-color: #ec720d;
    border-color: #ec720d;
    color: white;
}

.btn-previous-step {
    border-color: black;
}

.btn-next-step {
    border-color: #ec720d;
}

.btn-step:hover > .arrow {
    border-color: white;
}

.arrow {
    border: solid #ec720d;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.arrow-right {
    transform: rotate(-45deg);
    margin-left: 10px;
}

.arrow-left {
    transform: rotate(135deg);
    margin-right: 10px;
}

.grid-center-content {
    text-align: center;
    float: left;
    width: 100%;
}

.info-text {
    text-align: left;
    color: grey;
}

.step-opt-selector {
    background-color: green;
    width: 100%;
}

.opt-selection-row {
    background-color: #bfbfbf;
    color: white;
    padding: 10px;
}

.opt-text {
    font-weight: bold;
}

.opt-selection-box {
    background-color: white;
    margin: 5px;
}

.panel-title {
    color: gray;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}

.seperator-text {
    font-weight: bold;
}

.info-text-bold {
    font-weight: bold;
}

.text-desc-small {
    font-size: 1.3rem;
    font-weight: normal;
}

.align-content-right {
    align-content: right;
    text-align: right;
}

.inline-block {
    display: inline-block;
}

img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    height: auto;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}

.field-color-preview {
    display: inline-block;
    margin-left: 2px;
    margin-right: 2px;
}

.field-color-preview-selected {
    border: 2px solid orange;
    margin-left: 0px;
    margin-right: 0px;
}

.text-circled {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid gray;
    font-size: 10px;
    position: relative;
    top: 83px;
    /* 140px */
    left: 32px;
    /* 90px */
    color: #000;
    line-height: 25px;
    text-align: center;
    background: #fff;
}

.quote-wrapper {
    margin-left: -15px;
    margin-right: -15px;
}

.img-fabrics {
    height: 100px;
    width: 100%;
}

.img-accessorie {
    width: 80px;
    height: 60px;
}

.img-accessorie-small {
    width: 20px;
    height: 20px;
}

.hide-table-entry {
    display: none;
}

.img-accessorie-round {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    float: right;
}

.input-accessorie {
    width: 100px;
}

.div-stacked {
    position: absolute;
    top: 5px;
    right: 20px;
}

.config-sum {
    padding: 15px;
    border-style: solid;
    border-width: 1px;
    border-color: lightgray;
}

.input-fields {
    width: 75px;
}

/* Customize the label (the container) */

.radio-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
}

/* Hide the browser's default radio button */

.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */

.radio-container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */

.radio-container input:checked ~ .checkmark {
    background-color: white;
}

/* When the radio button is checked, add a blue background */

.radio-container input:checked ~ .opt-selection-row {
    background-color: green;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */

.radio-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */

.radio-container .checkmark:after {
    left: 10px;
    top: 6px;
    width: 5px;
    height: 10px;
    border: solid orange;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}
